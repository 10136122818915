import React from "react";

import marked from "marked";

export default (props) => {
  let formattedContent = marked(props.content);
  formattedContent = formattedContent.replace('<p>', '');
  formattedContent = formattedContent.replace('</p>', '');

  return (
    <div className="sidebar-note">
      <p dangerouslySetInnerHTML={{__html: formattedContent}} />
    </div>
  );
}
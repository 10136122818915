import React from "react";

import marked from "marked";

export default (props) => {
  let formattedText = marked(props.content.text);
  formattedText = formattedText.replace('<p>', '');
  formattedText = formattedText.replace('</p>', '');

  return (
    <p className="pull-quote"
       id={props.id}
       dangerouslySetInnerHTML={{ __html: formattedText }} />
  );
}

import React from "react";

import marked from "marked";

export default (props) => {
  const imageResult = props.allImages.filter(image => {
    return (image.node.reference === props.content.reference);
  });

  if (imageResult && imageResult.length > 0) {
    const image = imageResult[0].node;
    const caption = (image.caption) ?
      (<p className="caption"
        dangerouslySetInnerHTML={
          {__html: marked(image.caption).replace('<p>', '').replace('</p>', '')}}
       />) :
      null;
    const credit = (image.credit) ?
      (<p className="credit"
        dangerouslySetInnerHTML={
          {__html: marked(image.credit).replace('<p>', '').replace('</p>', '')}}
       />) :
      null;

    const classes = (props.fullWidth) ? 'full-width-image' : 'sidebar-image';
    const inPageImage = (props.fullWidth) ? image.fullPath : image.thumbPath;

    return (
      <div className={classes}>
        <a href={`/images/content/${image.fullPath}`}
           data-fancybox=''>
          <img
            src={`/images/content/${inPageImage}`}
            alt={image.alt}
          />
        </a>
        {caption}
        {credit}
      </div>
    )
  }

  return null;
}

import React from 'react';

import { Map, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import './ut-dances-map.scss';

class UTDancesMap extends React.Component {
  constructor(props) {
    super(props);

    this.locations = [
      {
        id: 'koc',
        label: 'Knights of Columbus Hall',
        labelPosition: 'right',
        coords: [30.2707806, -97.7436338],
      },
      {
        id: 'womens-gym',
        label: 'Women’s Gym',
        labelPosition: 'left',
        coords: [30.286445, -97.741259],
      },
      {
        id: 'gregory-gym',
        label: 'Gregory Gym',
        labelPosition: 'right',
        coords: [30.284103, -97.736530],
      },
      {
        id: 'texas-union',
        label: 'Texas Union',
        labelPosition: 'right',
        coords: [30.286744, -97.741176],
      },
    ];

    this.mapRef = React.createRef();
    this.wrapperRef = React.createRef();

    this.drawPoints = this.drawPoints.bind(this);
    this.fitMap = this.fitMap.bind(this);
  }

  componentDidMount() {
    this.fitMap();
    this.drawPoints();

    window.addEventListener('resize', this.fitMap);
  }

  drawPoints() {
    const L = require('leaflet');
    const map = this.mapRef.current.leafletElement;

    const markers = this.locations.map(location => {
      const textLabel = L.divIcon({className: 'ut-dances-map-label',
                                   html: `<span class="contents ${location.labelPosition}">${location.label}</span>`});
      const circle = L.circleMarker(
          location.coords, 
          {
            color: '#fd7e14',
            stroke: true,
            weight: 2,
            radius: 12,
            fillOpacity: .8,
          });
      const label = L.marker(location.coords, { icon: textLabel });
      
      circle.addTo(map);
      label.addTo(map);

      const scrollToLocation = () => {
        document.getElementById(location.id).scrollIntoView({ behavior: 'smooth' });
      };
      circle.addEventListener('click', scrollToLocation);
      label.addEventListener('click', scrollToLocation);
    });
  }

  fitMap() {
    const L = require('leaflet');
    const map = this.mapRef.current.leafletElement;

    const allPoints = this.locations.map(location => location.coords);

    map.fitBounds(L.polyline(allPoints).getBounds());
  }

  render() {
    if (typeof window !== 'undefined') {
      return (
        <div className="ut-dances-map-wrapper" ref={this.wrapperRef}>
          <Map ref={this.mapRef}
            className="map"
            dragging={false}
            boxZoom={false}
            zoomControl={false}
            doubleClickZoom={false}
            scrollWheelZoom={false}
            touchZoom={false}
          >
            <TileLayer
              url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            />
          </Map>
        </div>
      );
    }

    return (<div className="ut-dances-map-wrapper" ref={this.wrapperRef} />);
  }
}

export default UTDancesMap;
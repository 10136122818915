import React from "react";

import { Map, Marker, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import iconShadow from "leaflet/dist/images/marker-shadow.png";

import "../../scss/components/narratives/simple-map.scss";

class SimpleMap extends React.Component {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    let L = require('leaflet');

    const DefaultIcon = L.icon({
      iconUrl: "/images/interface/maps/marker.png",
      iconRetinaUrl: "/images/interface/maps/marker-2x.png",
      iconSize: [25, 43],
      iconAnchor: [12.5, 43],
      popupAnchor: [1, -24],
      shadowUrl: iconShadow,
    });

    L.Marker.prototype.options.icon = DefaultIcon;
  }

  render() {
    if (typeof window !== 'undefined') {
      return (
        <div className="simple-map-wrapper" ref={this.wrapperRef}>
          <Map ref={this.mapRef}
            className="map"
            center={this.props.content.center}
            zoom={this.props.content.zoom}
            dragging={this.props.content.dragControl}
            zoomControl={this.props.content.zoomControl}
            boxZoom={this.props.content.zoomControl}
            doubleClickZoom={this.props.content.zoomControl}
            scrollWheelZoom={this.props.content.zoomControl}
            touchZoom={this.props.content.zoomControl}
            minZoom={this.props.content.minZoom}
            maxZoom={this.props.content.maxZoom}
          >
            <TileLayer
              url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            />
            {this.props.content.markers.map((marker, idx) => (
              <Marker position={marker.position} key={idx} />
            ))}
          </Map>
        </div>
      );
    }

    return (<div className="simple-map-wrapper" ref={this.wrapperRef} />);
  }
}

export default SimpleMap;
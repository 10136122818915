import React from "react";

import moment from "moment";

import { Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import TimelineGroup from "./timeline-group";

import cities from "./cities";
import dateSets from "./date-sets";
import dates from "./dates";
import timelines from "./timelines";

import "./gardner-tour-1930.scss";

class GardnerTour1930 extends React.Component {
  constructor(props) {
    super(props);

    this.originalPointIds = [];

    this.mapRef = React.createRef();
    this.mapWrapperRef = React.createRef();

    this.clearMap = this.clearMap.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.showPopupForCity = this.showPopupForCity.bind(this);
    this.showPopupForDate = this.showPopupForDate.bind(this);
    this.drawOriginalPoints = this.drawOriginalPoints.bind(this);
    this.drawSet = this.drawSet.bind(this);
    this.highlightCity = this.highlightCity.bind(this);
    this.dateClick = this.dateClick.bind(this);
  }

  componentDidMount() {
    const L = require('leaflet');
    const map = this.mapRef.current.leafletElement;

    const allPoints = cities.map(city => [city['lat'], city['lon']]);

    this.originalPointIds = this.drawOriginalPoints();

    map.fitBounds(L.polyline(allPoints).getBounds());
  }

  clearMap() {
    const map = this.mapRef.current.leafletElement;

    for (let i in map._layers) {
      if (!this.originalPointIds.includes(map._layers[i]._leaflet_id)) {
        if (map._layers[i]._path !== undefined) {
          try {
            map.removeLayer(map._layers[i]);
          }
          catch(e) {
            console.log("problem with " + e + map._layers[i]);
          }
        }
      }
    }
  }

  showPopup(content, lat, lng) {
    const L = require('leaflet');
    const map = this.mapRef.current.leafletElement;

    L.popup({ closeButton: false })
      .setLatLng([lat, lng])
      .setContent(content)
      .openOn(map);
  }

  showPopupForCity(cityReference) {
    const events = dates.filter(d => d['city'] === cityReference);
    const city = cities.filter(c => c['reference'] === cityReference)[0];

    let content = `<span class="location">${city['city']}</span>`;
    events.forEach(event => {
      content += `<span class="date">${moment(event['date']).format('dddd MMMM D')}</span>`;
      if (event['note'].length > 0) {
        content += `<span class="note">${event['note']}</span>`;
      }
    });

    this.showPopup(content, city['lat'], city['lon']);
  }

  showPopupForDate(date) {
    const event = dates.filter(d => d['date'] === date)[0];
    const city = cities.filter(c => c['reference'] === event['city'])[0];

    let content = `<span class="location">${city['city']}</span>`;
    content += `<span class="date">${moment(event['date']).format('dddd MMMM D')}</span>`;
    if (event['note'].length > 0) {
      content += `<span class="note">${event['note']}</span>`;
    }

    this.showPopup(content, city['lat'], city['lon']);
  }

  drawOriginalPoints() {
    const L = require('leaflet');
    const map = this.mapRef.current.leafletElement;

    let markers = [];

    cities.forEach(city => {
      const radius = (city['reference'] === 'austin') ? 10 : 5;
      const opacity = (city['reference'] === 'austin') ? 1 : .5;
      const point = [city['lat'], city['lon']];
      const marker = L.circleMarker(point, {color: 'black', stroke: false, radius: radius, fillOpacity: opacity }).addTo(map);

      marker.on('click', e => this.markerClick(e, city['reference']));

      markers.push(marker);
    });

    const pointIds = markers.map(point => point._leaflet_id);

    return pointIds;
  }

  drawSet(date) {
    const L = require('leaflet');
    const map = this.mapRef.current.leafletElement;

    const austin = cities.filter(city => city['reference'] === "austin")[0];
    const dateSet = dateSets.filter(set => set['dates'].includes(date))[0];

    let coords = [{ coords: [austin['lat'], austin['lon']], reference: 'austin' }];

    let dateList;
    if (dateSet) {
      dateList = dateSet['dates'];
    } else {
      dateList = [date];
    }

    let color = 'black';
    if (dateSet) {
      color = dateSet['color'];
    }

    dateList.forEach(date => {
      const thisDate = dates.filter(d => d['date'] === date)[0];
      const thisCity = cities.filter(c => c['reference'] === thisDate['city'])[0];
      const point = { coords: [thisCity['lat'], thisCity['lon']], reference: thisCity['reference'] };

      coords.push(point);
    });

    coords.push({ coords: [austin['lat'], austin['lon']], reference: 'austin' });

    coords.forEach(point => {
      const marker = L.circleMarker(point['coords'], {color: color, stroke: false, radius: 10, fillOpacity: .5}).addTo(map);

      marker.on('click', (e) => {
        e.originalEvent.preventDefault();
        this.highlightCity(point['reference']);
      })
    });
    
    coords = coords.map(c => c['coords']);
    const path = L.polyline(coords, { color: color }).addTo(map);

    return path;
  }

  highlightCity(cityReference) {
    const L = require('leaflet');
    const map = this.mapRef.current.leafletElement;

    const events = dates.filter(date => date['city'] === cityReference);

    this.clearMap();
    let coords = [];
    events.forEach(event => {
      const polyline = this.drawSet(event['date']);
      coords = coords.concat(polyline.getLatLngs());
    });
    map.fitBounds(L.polyline(coords).getBounds());

    this.showPopupForCity(cityReference);
  }

  dateClick(e, date) {
    const map = this.mapRef.current.leafletElement;
    const mapWrapper = this.mapWrapperRef.current;

    e.preventDefault();

    mapWrapper.scrollIntoView({ behavior: 'smooth'});
    this.clearMap();
    let polyline = this.drawSet(date);
    map.fitBounds(polyline.getBounds());
    this.showPopupForDate(date);
  }

  markerClick(e, cityReference) {
    e.originalEvent.preventDefault();
    this.highlightCity(cityReference);
  }

  render() {
    if (typeof window !== 'undefined') {
      return (
        <div ref={this.mapWrapperRef} className="gtour1930-display-wrapper">
          <Map ref={this.mapRef}
            scrollWheelZoom={false}
            center={[cities[0]['lat'], cities[0]['lon']]}
            zoom={10}
            minZoom={7}
            maxZoom={12}
            style={{height: '540px'}}
          >
            <TileLayer
              url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            />
          </Map>
          <div className="timelines-wrapper">
            {timelines.map((timelineGroup, idx) => (
              <TimelineGroup key={idx}
                            timelineGroup={timelineGroup}
                            dateClick={this.dateClick} />
            ))}
          </div>
        </div>
      );
    }

    return (
      <div ref={this.mapWrapperRef} className="gtour1930-display-wrapper">
        <p>This interactive map feature requires JavaScript to function.</p>
      </div>
    );
  }
}

export default GardnerTour1930;

import React, { useEffect, useRef } from 'react';

import { Map, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import './military-bases-map.scss';

const mapLocations = [
  {
    id: 'swift',
    label: 'Camp Swift',
    labelPosition: 'right',
    coords: [30.191014893717004, -97.29472314287283]
  },
  {
    id: 'hood',
    label: 'Camp Hood',
    labelPosition: 'right',
    coords: [31.196171287929296, -97.73398888811133]
  },
  {
    id: 'bergstrom',
    label: 'Bergstrom Army Air Field',
    labelPosition: 'left',
    coords: [30.19762872101217, -97.66636363112616]
  },
  {
    id: 'mabry',
    label: 'Camp Mabry',
    labelPosition: 'left',
    coords: [30.313546687196904, -97.76625462185743]
  }
];

const drawPoints = (mapRef) => {
  const L = require('leaflet');
  const map = mapRef.current.leafletElement;

  const markers = mapLocations.map(location => {
    const textLabel = L.divIcon({className: 'military-bases-map-label',
                                 html: `<span class="contents ${location.labelPosition}">${location.label}</span>`});
    const circle = L.circleMarker(
        location.coords,
        {
          color: '#fd7e14',
          stroke: true,
          weight: 2,
          radius: 12,
          fillOpacity: .8,
        });
    const label = L.marker(location.coords, { icon: textLabel });

    circle.addTo(map);
    label.addTo(map);

    const scrollToLocation = () => {
      document.getElementById(location.id).scrollIntoView({ behavior: 'smooth' });
    };
    circle.addEventListener('click', scrollToLocation);
    label.addEventListener('click', scrollToLocation);
  });
};

const fitMap = (mapRef) => {
  const L = require('leaflet');
  const map = mapRef.current.leafletElement;

  const allPoints = mapLocations.map(location => location.coords);

  map.fitBounds(L.polyline(allPoints).getBounds());
};

export default (props) => {
  const mapRef = useRef();
  const wrapperRef = useRef();

  useEffect(() => {
    fitMap(mapRef);
    drawPoints(mapRef);

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', () => fitMap(mapRef));
    }
  }, []);

  if (typeof window !== 'undefined') {
    return (
      <div className="military-bases-map-wrapper" ref={ wrapperRef }>
        <Map ref={ mapRef }
          className="map"
          dragging={ false }
          boxZoom={ false }
          zoomControl={ false }
          doubleClickZoom={ false }
          scrollWheelZoom={ false }
          touchZoom={ false }
        >
          <TileLayer
            url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          />
        </Map>
      </div>
    );
  }

  return <div className="military-bases-map-wrapper" ref={ wrapperRef } />;
};
